import {Injectable} from '@angular/core';
import {CurrentUserDto} from '@api';

export enum AuthenticationMethod {
  BasicAuth='BasicAuth',
  Sso='Sso'
}

@Injectable({
  providedIn: 'root'
})
export class AuthStore {
  private _user?: CurrentUserDto;

  private NEXT_TOKEN_IDENTIFIER = 'nextToken';
  private PICKLIST_TOKEN_IDENTIFIER = 'picklistToken';
  private AUTH_METHOD_IDENTIFIER = 'authMethod';
  private SSO_SIGNATURE_IDENTIFIER = 'ssoSig';

  public reset(): void {
    this.setCurrentUser(undefined);
    this.setNextAuthToken(undefined);
    this.setPicklistAuthToken(undefined);
    this.setAuthenticationMethod(undefined);
    this.setSsoSignature(undefined);
  }

  public setCurrentUser(user?: CurrentUserDto): void {
    this._user = Object.freeze(user);
  }

  public getCurrentUser(): CurrentUserDto | undefined {
    return this._user;
  }

  public getNextAuthToken(): string | null {
    return localStorage.getItem(this.NEXT_TOKEN_IDENTIFIER);
  }

  public setNextAuthToken(token?: string): void {
    if (token) {
      localStorage.setItem(this.NEXT_TOKEN_IDENTIFIER, token);
    } else {
      localStorage.removeItem(this.NEXT_TOKEN_IDENTIFIER);
    }
  }

  public getPicklistAuthToken(): string | null {
    return localStorage.getItem(this.PICKLIST_TOKEN_IDENTIFIER);
  }

  public setPicklistAuthToken(token?: string): void {
    if (token) {
      localStorage.setItem(this.PICKLIST_TOKEN_IDENTIFIER, token);
    } else {
      localStorage.removeItem(this.PICKLIST_TOKEN_IDENTIFIER);
    }
  }

  public getAuthenticationMethod(): AuthenticationMethod | null {
    const method = localStorage.getItem(this.AUTH_METHOD_IDENTIFIER);
    if(method) {
      return method as AuthenticationMethod;
    } else {
      return null;
    }
  }

  public setAuthenticationMethod(method?: AuthenticationMethod): void {
    if(method) {
      localStorage.setItem(this.AUTH_METHOD_IDENTIFIER, AuthenticationMethod[method]);
    } else {
      localStorage.removeItem(this.AUTH_METHOD_IDENTIFIER);
    }
  }

  public getSsoSignature(): string | null {
    return localStorage.getItem(this.SSO_SIGNATURE_IDENTIFIER);
  }

  public setSsoSignature(signature?: string): void {
    if(signature) {
      localStorage.setItem(this.SSO_SIGNATURE_IDENTIFIER, signature);
    } else {
      localStorage.removeItem(this.SSO_SIGNATURE_IDENTIFIER);
    }
  }
}
