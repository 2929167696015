
<div>
    <p class="qrCodeLabel" *ngIf="this.qrCodeLabel">
        {{'qrcode.print.message' | translate}} {{this.qrCodeLabel}}
    </p>
    <qrcode *ngIf="qrCodeData != null"
            [qrdata]="qrCodeData"
            [width]="300"
            [elementType]="qrElementType"></qrcode>
</div>
