import {NgModule} from '@angular/core';
import {NavbarComponent} from './component/navbar/navbar.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {PrintLayoutComponent} from '@common/print/print-layout.component';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [NavbarComponent, PrintLayoutComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatMenuModule,
    RouterModule.forChild([])
  ],
  exports: [
    NavbarComponent,
    PrintLayoutComponent,
    TranslateModule
  ],
})
export class ClfCommonModule {
}
