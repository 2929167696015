import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {NAVIGATION} from '../constant/navigation';
import {AuthenticationMethod, AuthStore} from '@common/store/auth.store';
import {AuthService} from '@common/service/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public constructor(
    private router: Router,
    private authStore: AuthStore,
    private authService: AuthService
  ) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(async (error) => this.handleAuthError(error)));
  }

  private async handleAuthError(err: HttpErrorResponse): Promise<HttpResponse<unknown>> {
    if (err.status === 401 || err.status === 403) {
      if (this.authStore.getAuthenticationMethod() == AuthenticationMethod.Sso) { //sso
        try {
          const refreshSuccess = await this.authService.refreshSso();
          if(refreshSuccess) {
            return new HttpResponse<unknown>();
          } else {
            throw err;
          }
        } catch (e) {
          throw e;
        }
      } else { // basic auth or no authentication method
        this.authStore.reset();
        await this.router.navigateByUrl(NAVIGATION.Login);
        throw err;
      }
    }
    throw err;
  }
}
