import {NgModule} from '@angular/core';
import {ImagePreviewComponent} from '@sub-component/image-preview/image-preview.component';
import {NgxImageZoomModule} from 'ngx-image-zoom';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [ImagePreviewComponent],
  imports: [
    NgxImageZoomModule,
    CommonModule
  ],
  exports: [
    ImagePreviewComponent
  ],
  providers: []
})
export class ImagePreviewModule {
}
