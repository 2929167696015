import {Injectable} from '@angular/core';
import {
  PicklistCarLicenceExecutionDto,
  PicklistCarLicenceResultDto,
  PicklistService,
  PictureMetadataDto,
  PresignedFileUploadDto
} from '@api';
import {catchError, filter, mergeMap, take} from 'rxjs/operators';
import {BehaviorSubject, interval, Observable, of} from 'rxjs';
import {ModelDto, NextService, ShowModelsResponseDto} from '@next-api';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';

type ProcessingResult = PicklistCarLicenceResultDto | null;
type PictureMetadata = PictureMetadataDto | undefined;

@Injectable({
  providedIn: 'root'
})
export class ProcessingService {

  private uploading = new BehaviorSubject<boolean>(false);
  private result = new BehaviorSubject<ProcessingResult>(null);
  private imageConversionPicture = new BehaviorSubject<PictureMetadata>(undefined);
  private error = new BehaviorSubject<boolean>(false);

  public constructor(
    private readonly picklistService: PicklistService,
    private http: HttpClient,
    private readonly nextService: NextService,
    private readonly logger: NGXLogger
  ) {
  }

  public onFileInput(file: File): void {
    this.resetState();
    if (file) {
      this.uploading.next(true);
      let fileName = '';
      this.picklistService.getPresignedUploadUrl()
        .pipe(
          mergeMap((response: PresignedFileUploadDto) => {
            const uploadUrl = response.presignedUrl;
            fileName = response.fileName;
            return this.http.put(uploadUrl, file);
          }),
          mergeMap(() => {
            this.uploading.next(false);
            return this.picklistService.startProcessingExecution(fileName);
          }),
          catchError(() => of({executionId: null}))
        ).subscribe((data: PicklistCarLicenceExecutionDto) => {
        if (data.executionId == null) {
          this.error.next(true);
          return;
        }
        return interval(1500)
          .pipe(mergeMap(_ => {
              return this.picklistService.getResult(data.executionId, 'response');
            }),
          )
          .pipe(filter(res => res.status !== 202))
          .pipe(take(1))
          .subscribe(res => {
              if (res.status !== 200) {
                this.error.next(true);
              }
              const executionResult = res.body;
              const pictureMetadata = executionResult?.pictureMetadataDto;
              if (pictureMetadata !== null) {
                this.imageConversionPicture.next(pictureMetadata);
              }

              const result = executionResult?.result;
              if (result === null || result === undefined) {
                this.error.next(true);
                return;
              }
              if (result?.nationalerCode !== null && result?.nationalerCode !== '') {
                this.nextService.showModelsByQuery(result?.nationalerCode || '').toPromise()
                  .then((response: ShowModelsResponseDto) => {
                    if ((result !== null) && response.models.length == 1) {
                      result.tecDocId = response.models.find((value: ModelDto) => true)?.id?.toString() || '';
                    }
                    this.result.next(result);
                  })
                  .catch((err: never) => {
                    this.logger.error(err);
                    this.result.next(result);
                  });
              } else {
                this.result.next(result);
              }
            },
            err => {
              this.error.next(true);
              throw err;
            });
      });
    }
  }

  private resetState() {
    this.result.next(null);
    this.error.next(false);
  }

  public get resultObservable(): Observable<ProcessingResult> {
    return this.result.asObservable();
  }

  public get imageConversionPictureObservable(): Observable<PictureMetadata> {
    return this.imageConversionPicture.asObservable();
  }

  public get errorObservable(): Observable<boolean> {
    return this.error.asObservable();
  }

  public get uploadingObservable(): Observable<boolean> {
    return this.uploading.asObservable();
  }
}
