<div class="content">
    <div class="qrcode" [hidden]="qrCodeData == null">
        <div class="content">
            <div class="single-content margin-26">
                <span class="upload-header">{{'qrcode.welcome' | translate}}</span>
            </div>
            <div class="single-content margin-26">
                <span class="upload-text">{{'qrcode.description' | translate}}</span>
            </div>
            <div class="single-content margin-26">
                <qrcode *ngIf="qrCodeData != null" [qrdata]="qrCodeData" [width]="150"
                        colorLight="#fafafaff"
                        [elementType]="qrElementType"></qrcode>
            </div>
            <div class="single-content">
                <button class="custom-button" mat-flat-button (click)="onPrint()">
                    <mat-icon class="custom-icon" aria-hidden="false" aria-label="upload icon">print</mat-icon>
                    {{'qrcode.print' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
