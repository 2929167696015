<div class="image-preview-content">
    <lib-ngx-image-zoom *ngIf="rotatedImage"
            [thumbImage]=rotatedImage
            [fullImage]=rotatedImage
                        enableLens="true"
                        magnification="2"
                        lensWidth="200"
                        lensHeight="200"
    ></lib-ngx-image-zoom>
</div>

