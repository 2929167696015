import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PicklistCarLicenceResultDto, PictureMetadataDto} from '@api';
import {ProcessingService} from '@common/service/processing.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {NAVIGATION} from '@common/constant/navigation';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  public file: File | null = null;
  public result: PicklistCarLicenceResultDto | null = null;
  public uploading = false;
  public imageConversionPicture: PictureMetadataDto | null = null;

  private paramMapSub: Subscription | undefined;

  public constructor(
    private processingService: ProcessingService,
    private readonly route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private router: Router
  ) {
    this.processingService.resultObservable.subscribe(result => {
      this.result = result;
    });
    this.processingService.errorObservable.subscribe(hasErrorOccurred => {
      if (hasErrorOccurred) {
        this.translate.get('dashboard.processing.error').toPromise().then(errorMessage => {
          this.snackBar.open(errorMessage, 'OK');
        });
        // could do further error handling
        this.file = null;
      }
    });

    this.processingService.uploadingObservable.subscribe(isUploading => {
      this.uploading = isUploading;
    });
    this.processingService.imageConversionPictureObservable.subscribe(pictureMetadata => {
      if(pictureMetadata && pictureMetadata.pictureUrl) {
        this.imageConversionPicture = pictureMetadata;
      }
    });
  }

  public ngOnInit() {
    this.paramMapSub = this.route.paramMap.subscribe((params: ParamMap) => {
      const payload = params.get('payload');

      if (payload != null) {
        const arr = JSON.parse(atob(payload));
        if (arr[0] === 'v1') {
          arr[12] = '';
          this.result = {
            vin: arr[1],
            erstzulassung: arr[2],
            handelsbezeichnung: arr[3],
            hubraum: arr[4],
            kennzeichen: arr[5],
            klasse: arr[6],
            leistung: arr[7],
            marke: arr[8],
            nationalerCode: arr[9],
            typ: arr[10],
            tecDocId: arr[11],
            kilometerstand: arr[12],
            motortype: arr[13]
          };
        } else {
          throw new Error('unknown payload version');
        }

      }
    });

  }

  public ngOnDestroy() {
    this.paramMapSub?.unsubscribe();
  }

  public fileUploaded(file: File): void {
    this.file = file;
    this.processingService.onFileInput(file);
  }

  public cancelProcess(): void {
    this.file = null;
    this.result = null;
    this.router.navigate([NAVIGATION.Dashboard]);
  }
}
