import {Injectable} from '@angular/core';
import {PicklistCarLicenceResultDto} from '@api';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QrService {

  public createQrCodeURL(result: PicklistCarLicenceResultDto): string {
    const data = [
      'v1',
      result.vin,
      result.erstzulassung,
      result.handelsbezeichnung,
      result.hubraum,
      result.kennzeichen,
      result.klasse,
      result.leistung,
      result.marke,
      result.nationalerCode,
      result.typ,
      result.tecDocId || '',
      '',
      result.motortype
    ];
    const dataJSON = JSON.stringify(data);
    const base64 = btoa(dataJSON);
    return `${environment.qrUrl}/${base64}`;
  }
}
