import {Component, Input} from '@angular/core';
import {PictureMetadataDto} from '@api';

@Component({
  selector: 'image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent{

  @Input()
  public set imageMetaData(imageMetaData: PictureMetadataDto) {
    const imageUrl = imageMetaData.pictureUrl;
    const orientation = imageMetaData.orientation;
    if(imageUrl != null && orientation != null) {
      this.rotateAndLoadImage(imageUrl, orientation);
    }
  }

  public rotatedImage: ArrayBuffer | string | null = null;

  public rotateAndLoadImage(imageUrl: string, orientation: number): void {
    if(orientation == 0) {
      this.rotatedImage = imageUrl;
      return;
    }

    // get needed rotation
    const rotation = 360 - orientation;

    // load image locally
    const image = new Image();
    image.crossOrigin = 'Anonymous'; //https://stackoverflow.com/a/31653587
    image.src = imageUrl;
    image.onload = () => {
      // create a canvas with dimensions of image
      // if orientation will change swap width and height
      const canvas = document.createElement('canvas');
      if (rotation == 90 || rotation == 270) {
        canvas.width = image.height;
        canvas.height = image.width;
      } else {
        canvas.width = image.width;
        canvas.height = image.height;
      }

      //get canvas context and execute multiple translations
      const context = canvas.getContext('2d');
      if (context) {
        context.translate(canvas.width / 2, canvas.height / 2); //translate yourself into the middle of the image
        context.rotate(rotation * Math.PI / 180); // rotate the canvas
        //translate back to original point using the image sizes to ensure we start drawing at the right point
        context.translate(-image.width / 2, -image.height / 2);
        context.drawImage(image, 0, 0); //draw image
        context.setTransform(1, 0, 0, 1, 0, 0); //reset context
        this.rotatedImage = canvas.toDataURL('image/png'); //translate to base64
      }
    };
  }


}
