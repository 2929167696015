import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthStore} from '../store/auth.store';
import {AuthService} from '../service/auth.service';
import {NAVIGATION} from '../constant/navigation';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  private ssoPath = `/${NAVIGATION.Sso}`;

  public constructor(
    private router: Router,
    private authStore: AuthStore,
    private authService: AuthService,
  ) {
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    //if startswith -> sso -> component handles it, could be improved with more sophisticated routing config
    if(!state.url.startsWith(this.ssoPath)) {
      if (!this.authStore.getNextAuthToken()) {
        this.authService.setRedirectUrl(state.url);
        return this.router.parseUrl(NAVIGATION.Login);
      }
      if (!this.authStore.getCurrentUser()) {
        try {
          await this.authService.refreshCurrentUser();
        } catch (e) {
          this.authService.setRedirectUrl(state.url);
          return this.router.parseUrl(NAVIGATION.Login);
        }
      }
    }
    return true;
  }

}
