import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {MobileService} from '@common/service/mobile.service';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  public isPrinting = false;

  public constructor(private router: Router,
                     private mobileService: MobileService) {
  }

  public printQrCode(qrCodeLabel: string, qrCodeData: string) {
    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          print: ['print', 'qrcode', qrCodeLabel, qrCodeData]
        }
      }]);
  }

  public onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      // delay is needed since printing is non blocking on mobile, which results in a redirect before the print window can render
      // the content
      if(this.mobileService.isMobileOrTablet()) {
        setTimeout(() => {
          this.router.navigate([{outlets: {print: null}}]);
        }, 2000);
      } else {
        this.router.navigate([{outlets: {print: null}}]);
      }
    },1000);
  }
}
