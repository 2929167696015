import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {PicklistCarLicenceResultDto} from '@api';
import {QrComponent} from '@sub-component/qr-module/qr.component';
import {UrlResponseDto} from '@next-api';
import {ResultComponent} from '@sub-component/result-module/result.component';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

enum Steps {
  Upload_Photo = 0,
  Results,
  QrCode,
  Next,
}

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, AfterViewInit {

  @Input() public result: PicklistCarLicenceResultDto | null;
  @Output() public cancel = new EventEmitter();

  @ViewChild('horizontalStepper') private horizontalStepper: MatStepper;
  @ViewChild('verticalStepper') private verticalStepper: MatStepper;
  @ViewChild('horizontalQR') private horizontalQR: QrComponent;
  @ViewChild('verticalQR') private verticalQR: QrComponent;
  @ViewChild(ResultComponent) private resultComponent: ResultComponent;

  public loading = false;
  public vertical = true;

  public constructor(private breakPointObserver: BreakpointObserver) {
  }

  public ngOnInit(): void {
    this.breakPointObserver
      .observe(['(min-width: 1100px)']) //desktop
      .subscribe((state: BreakpointState) => {
        this.vertical = !state.matches;
        this.ngAfterViewInit();
      });
  }

  public ngAfterViewInit(): void {
    if(this.getStepper()) {
      this.getStepper().next();
    }
  }

  public updatedResult(result: PicklistCarLicenceResultDto): void {
    this.result = result;
    this.getQRComponent().onQueryModels();
    this.stepperNext();
  }

  public cancelProcess(): void {
    this.cancel.emit();
  }

  public stepperNext(): void {
    this.getStepper().selected.completed = true;
    this.getStepper().selected.editable = false;
    this.getStepper().next();
    this.loading = false;
  }

  public onNext(): void {
    this.loading = true;
    this.actionBeforeStepChange();
  }

  public stepperBack(): void {
    const previousStep = this.getStepper().steps.get(this.getStepper().selectedIndex - 1);
    if (previousStep) {
      previousStep.completed = false;
      previousStep.editable = true;
      this.getStepper().previous();
    }
  }

  public get isValid(): boolean {
    if (this.getStepper()) {
      const currentStepIndex = this.getStepper().selectedIndex;
      switch (currentStepIndex) {
        case Steps.Upload_Photo:
        case Steps.QrCode:
        case Steps.Next:
          return true;
        case Steps.Results:
          return this.areResultsValid();
        default:
          return false;
      }
    }
    return false;
  }

  public get nextPossible(): boolean {
    if(this.getStepper()) {
      return this.getStepper().steps.length !== (this.getStepper().selectedIndex + 1);
    }
    return false;
  }

  public get backPossible(): boolean {
    if(this.getStepper()) {
      return this.getStepper().selectedIndex > 1; // only steps after first editable step can go back
    }
    return false;
  }

  private areResultsValid(): boolean {
    if (this.resultComponent && this.resultComponent.resultFormGroup) {
      return this.resultComponent.resultFormGroup.valid;
    }
    return false;
  }

  private actionBeforeStepChange(): void {
    const currentStepIndex = this.getStepper().selectedIndex;
    switch (currentStepIndex) {
      case Steps.Upload_Photo:
      case Steps.Next:
        return;
      case Steps.QrCode:
        this.getQRComponent().onNext();
        return;
      case Steps.Results:
        this.resultComponent.onNext();
        return;
      default:
        return;
    }
  }

  private getStepper(): MatStepper {
    if(this.vertical) {
      return this.verticalStepper;
    } else {
      return this.horizontalStepper;
    }
  }

  private getQRComponent(): QrComponent {
    if(this.vertical) {
      return this.verticalQR;
    } else {
      return this.horizontalQR;
    }
  }
}
