import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PicklistCarLicenceResultDto} from '@api';
import {QRCodeElementType} from 'angularx-qrcode/lib/types';
import {QrService} from '@common/service/qr.service';
import {PrintService} from '@common/print/print.service';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QrComponent {
  @Input() public result: PicklistCarLicenceResultDto | null = null;
  @Output() public done = new EventEmitter();

  public qrCodeData: string | null = null;
  public qrElementType: QRCodeElementType = QRCodeElementType.img;


  public constructor(
    private readonly qrService: QrService,
    private printService: PrintService
  ) {
  }

  public onQueryModels() {
    if (this.result !== null) {
      this.qrCodeData = this.qrService.createQrCodeURL(this.result);
    }
  }

  public onNext(): void {
    this.done.emit();
  }

  public onPrint() {
    const label = this.getQrCodeLabel();
    this.printService.printQrCode(label, this.qrCodeData || '');
  }

  private getQrCodeLabel(): string {
    if(this.result !== null){
      return this.result.nationalerCode.padStart(6, '0');
    } else {
      return '';
    }
  }
}
