import {NgModule} from '@angular/core';
import {DashboardComponent} from './dashboard.component';
import {RouterModule, Routes} from '@angular/router';
import {ClfCommonModule} from '@common/clf-common.module';
import {CommonModule} from '@angular/common';
import {DndDirective} from './directives/dnd.directive';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {QrService} from '@common/service/qr.service';
import {QRCodeModule} from 'angularx-qrcode';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {UploadComponent} from '@sub-component/upload-module/upload.component';
import {MatIconModule} from '@angular/material/icon';
import {StepperComponent} from '@sub-component/stepper-module/stepper.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {QrComponent} from '@sub-component/qr-module/qr.component';
import {ProcessingService} from '@common/service/processing.service';
import {ResultComponent} from '@sub-component/result-module/result.component';
import {NextComponent} from '@sub-component/next-module/next.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ImagePreviewModule} from '@sub-component/image-preview/image-preview.module';
import {LayoutModule} from '@angular/cdk/layout';
import {QrPrintComponent} from '@sub-component/qr-print-module/qr-print.component';


const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  }
];

@NgModule({
  declarations: [
    DashboardComponent,
    DndDirective,
    UploadComponent,
    StepperComponent,
    QrComponent,
    QrPrintComponent,
    ResultComponent,
    NextComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ClfCommonModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatTableModule,
        MatButtonModule,
        FormsModule,
        QRCodeModule,
        MatProgressBarModule,
        MatIconModule,
        MatStepperModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        ImagePreviewModule,
        ZXingScannerModule,
        MatSnackBarModule,
        LayoutModule
    ],
  providers: [
    QrService,
    ProcessingService
  ]
})
export class DashboardModule {
}
