import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NAVIGATION} from '@common/constant/navigation';
import {AuthenticationGuard} from '@common/guard/authentication.guard';
import {PrintLayoutComponent} from '@common/print/print-layout.component';
import {QrPrintComponent} from '@sub-component/qr-print-module/qr-print.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: NAVIGATION.Dashboard
  },
  {
    path: NAVIGATION.Login,
    loadChildren: async () => import('./login-module/login.module').then((m) => m.LoginModule)
  },
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: NAVIGATION.Dashboard,
        loadChildren: async () =>
          import('./dashboard-module/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: NAVIGATION.Dashboard + '/:payload',
        loadChildren: async () =>
          import('./dashboard-module/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: NAVIGATION.Sso + '/:user',
        loadChildren: async () =>
          import('./sso-module/sso.module').then((m) => m.SsoModule),
      }
    ],
  },
  {
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'qrcode/:qrcodelabel/:qrcodedata', component: QrPrintComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
