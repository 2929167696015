import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {NGXLogger} from 'ngx-logger';
import {AuthStore} from '@common/store/auth.store';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class AppInitService {
  public constructor(private translateService: TranslateService,
                     private title: Title,
                     private logger: NGXLogger,
                     private authStore: AuthStore) {
  }

  public init() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang('de');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use('de');
    this.translateService.get('title').toPromise().then(title => this.title.setTitle(title));

    const authToken = this.authStore.getPicklistAuthToken();
    if (authToken) {
      this.logger.setCustomHttpHeaders(new HttpHeaders({Authorization: 'Bearer ' + authToken}));
    }
  }
}
