import {ErrorHandler, Injectable} from '@angular/core';
import {CustomNGXLoggerService, NGXLogger} from 'ngx-logger';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  public constructor(private logger: NGXLogger) {
  }

  public handleError(error: unknown) {
    this.logger.error(error);
  }
}
