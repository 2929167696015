import {Component} from '@angular/core';
import {PicklistService} from '@api';
import {QRCodeElementType} from 'angularx-qrcode/lib/types';
import {NextService} from '@next-api';
import {QrService} from '@common/service/qr.service';
import {ActivatedRoute} from '@angular/router';
import {PrintService} from '@common/print/print.service';

@Component({
  selector: 'app-print-qr',
  templateUrl: './qr-print.component.html',
  styleUrls: ['./qr-print.component.scss']
})
export class QrPrintComponent {

  public qrCodeLabel: string | null = null;
  public qrCodeData: string | null = null;
  public qrElementType: QRCodeElementType = QRCodeElementType.img;

  public constructor(
    private readonly picklistService: PicklistService,
    private readonly nextService: NextService,
    private readonly qrService: QrService,
    private printService: PrintService,
    private readonly route: ActivatedRoute
  ) {
    if(route.snapshot.params['qrcodedata'] !== undefined) {
      this.qrCodeData = route.snapshot.params['qrcodedata'];
      this.qrCodeLabel = route.snapshot.params['qrcodelabel'];
      this.printService.onDataReady();
    }
  }
}
