import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {NAVIGATION} from '@common/constant/navigation';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {

  private static DASHBOARD_URL_PAYLOAD_REGEX = /\/dashboard\/((?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?)/;

  @Output() public uploaded = new EventEmitter<File>();

  @ViewChild('scanner') public scannerRef: ZXingScannerComponent;
  @ViewChild('fileDropRef') private input: ElementRef;

  public scanning = false;
  public hasPermission = false;
  public hasDevices = false;

  public constructor(
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly logger: NGXLogger,
    public translate: TranslateService
  ) {
  }

  public toggleScanning() {
    if (this.scanning) {
      this.stopScanning();
    } else {
      this.scannerRef.autostart = true;
      this.scanning = true;
      if (!this.hasPermission) {
        this.scannerRef.askForPermission()
          .then(permission => {
            this.hasPermission = permission;
            this.checkErrors();
          });
      }
    }
  }

  public showScanner(): boolean {
    return this.scanning;
  }

  public async scanSuccessHandler(data: string): Promise<void> {
    await this.stopScanning();
    await this.navigateToUrl(data);
  }

  public async permissionResponseHandler(permission: boolean): Promise<void> {
    this.hasPermission = permission;
  }

  public async devicesResponseHandler(devices: boolean): Promise<void> {
    this.hasDevices = devices;
  }

  /**
   * on file drop handler
   */
  public async onFileDropped(file: FileList) {
    if (this.scannerRef) {
      this.scannerRef.scanStop();
    }
    this.scanning = false;
    await this.onFileInput(file);
  }

  public async onFileInput(files: FileList | null) {
    if (files && files[0]) {
      this.logger.debug('trying to upload file ' + files[0].name);
      this.uploaded.emit(files[0]);
      this.input.nativeElement.value = '';
    } else {
      this.logger.error('onFileInput but no file found');
    }
  }

  private async stopScanning() {
    if (this.scannerRef) {
      this.scannerRef.scanStop();
    }
    this.scanning = false;
  }

  private async checkErrors() {
    if (this.scanning) {
      if (!this.hasDevices) {
        await this.stopScanning();
        await this.showDevicesError();
      } else if (!this.hasPermission) {
        await this.stopScanning();
        await this.showPermissionError();
      }
    }
  }

  private async showPermissionError() {
    const msg = await this.translate.get('scan.permission.error').toPromise();
    const ok = await this.translate.get('scan.permission.error.ok').toPromise();
    this.snackBar.open(msg, ok, {
      duration: 5000
    });
  }

  private async showDevicesError() {
    const msg = await this.translate.get('scan.device.error').toPromise();
    const ok = await this.translate.get('scan.device.error.ok').toPromise();
    this.snackBar.open(msg, ok, {
      duration: 5000
    });
  }

  private async navigateToUrl(url: string): Promise<boolean> {
    const payload = url.match(UploadComponent.DASHBOARD_URL_PAYLOAD_REGEX);
    if (payload && payload.length == 2) {
      return this.router.navigate([NAVIGATION.Dashboard, payload[1]]);
    }
    return Promise.reject(false);
  }
}
