<div class="navbar">
    <img class="logo" src="/assets/birner_logo.png" alt="birner logo"/>
    <ng-container *ngIf="currentUser">
        <mat-icon [matMenuTriggerFor]="menu" class="material-icons-outlined custom-icon" aria-hidden="false" aria-label="profile icon">account_circle</mat-icon>
        <mat-menu #menu="matMenu" class="custom-menu">
            <span class="user-name" mat-menu-item>{{currentUser.name}}</span>
            <button class="logout" mat-menu-item (click)="logout()">
                <mat-icon class="logout-icon" aria-hidden="false" aria-label="logout icon">logout</mat-icon>
                <span>{{'logout' | translate}}</span>
            </button>
        </mat-menu>
    </ng-container>
</div>
