import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PicklistCarLicenceResultDto} from '@api';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModelDto, NextService, ShowModelsResponseDto} from '@next-api';
import {InstantErrorMatcher} from '@sub-component/result-module/error-matcher';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent {

  @Input() public result: PicklistCarLicenceResultDto | null = null;
  @Output() public updated = new EventEmitter<PicklistCarLicenceResultDto>();

  public datePattern = '^([0-2][0-9]|(3)[0-1])(\\.)(((0)[0-9])|((1)[0-2]))(\\.)\\d{4}$';
  public nationalCodePattern = '^\\d{0,6}$';
  public mileagePattern = '^\\d+$';
  public nationalCodeMaxLength = 6;
  public resultFormGroup = new FormGroup({
    license_plate: new FormControl('', []),
    first_registration: new FormControl('', [
      Validators.pattern(this.datePattern)
    ]),
    mileage: new FormControl('', [Validators.pattern(this.mileagePattern)]),
    vin: new FormControl('', []),
    motortype: new FormControl('', []),
    national_code: new FormControl('', [
      Validators.required,
      Validators.pattern(this.nationalCodePattern),
      Validators.maxLength(this.nationalCodeMaxLength
      )]),
  });
  public instantErrorMatcher = new InstantErrorMatcher();

  public constructor(private readonly nextService: NextService,) {
  }

  public onNext(): void {
    this.updateData();
    this.fixupMileage();
  }

  public fixupMileage() {
    //if we use a 'number' input our pattern validation for whole numbers isn't working correctly,
    //but if we use a 'text' input angular saves a string on our number field which results in errors down the line.
    //as a quick workaround this piece of codes checks if the number field is a string and casts it if necessary.
    if (this.result !== null && this.result.kilometerstand !== null && typeof (this.result.kilometerstand) === 'string') {
      this.result.kilometerstand = parseInt(this.result.kilometerstand, 10);
    }
  }

  public updateData(): void {
    const toUpdate = this.result;
    if (toUpdate && toUpdate.nationalerCode != null && toUpdate.nationalerCode != '') {
      this.nextService.showModelsByQuery(toUpdate.nationalerCode).toPromise()
        .then((response: ShowModelsResponseDto) => {
          const tecDocId = response.models
            .find((value: ModelDto) => this.checkModelDto(value, toUpdate.nationalerCode))?.id?.toString() || '';
          if (tecDocId) {
            toUpdate.tecDocId = tecDocId;
            this.updated.next(toUpdate);
            return;
          }
          this.setNationalCodeInvalid();
          return;
        });
    } else {
      this.setNationalCodeInvalid();
    }
  }

  private setNationalCodeInvalid(): void {
    this.resultFormGroup.controls.national_code.setErrors({'invalid-national-code': true});
  }

  private checkModelDto(toCheck: ModelDto, nationalCode: string): boolean {
    if (toCheck.registrationNoDetails) {
      const registrationDetailsDto = toCheck.registrationNoDetails;
      if (registrationDetailsDto.registrationNo) {
        const nextNationalCode = registrationDetailsDto.registrationNo.replace(/^0+/, '');
        const nationalCodeTrimmed = nationalCode.replace(/^0+/, '');
        if (nextNationalCode === nationalCodeTrimmed) {
          return true;
        }
      }
    }
    return false;
  }
}
