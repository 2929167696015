<div class="upload" appDnd (fileDropped)="onFileDropped($event)">
    <div class="content">
        <div class="single-content margin-header">
            <span class="upload-header">{{'upload.welcome' | translate}}</span>
        </div>
        <div class="single-content margin-text">
            <span class="upload-text">{{'upload.description' | translate}}</span>
        </div>
        <div class="multiple-content margin-pictures" *ngIf="!showScanner()">
            <img class="sample_license" src="/assets/zulassungsschein_rueckseite.png" alt="sample car license"/>
            <img class="sample_qr" src="/assets/qr.png" alt="sample qr code"/>
        </div>

        <div [hidden]="!showScanner()">
            <div class="single-content">
                <zxing-scanner
                        class="custom-scanner"
                        #scanner
                        [autostart]="false"
                        [enable]="scanning"
                        [autofocusEnabled]="true"
                        [torch]="false"
                        (scanSuccess)="scanSuccessHandler($event)"
                        (permissionResponse)="permissionResponseHandler($event)"
                        (hasDevices)="devicesResponseHandler($event)"
                ></zxing-scanner>
            </div>
        </div>

        <div class="button-content">
            <button class="custom-button" mat-flat-button>
                <mat-icon class="custom-icon" aria-hidden="false" aria-label="upload icon">upload</mat-icon>
                {{'upload.button' | translate}}
                <input type="file" accept="image/*,.pdf" #fileDropRef id="fileDropRef" (change)="onFileInput(fileDropRef.files)"/>
            </button>
            <button class="custom-button margin-button" mat-flat-button (click)="toggleScanning()">
                <mat-icon class="custom-icon" aria-hidden="false" aria-label="scan icon">camera_alt</mat-icon>
                {{(!showScanner() ? 'scan.button' : 'scan.button.cancel') | translate}}
            </button>
        </div>

    </div>
</div>

