import {Component} from '@angular/core';
import {PrintService} from '@common/print/print.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public constructor(public readonly printService: PrintService) {
  }
}
