import {Injectable} from '@angular/core';
import {CurrentUserDto, TokenService, UserService} from '@api';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationMethod, AuthStore} from '../store/auth.store';
import {NextService} from '@next-api';
import {NGXLogger} from 'ngx-logger';

interface LoginResponse extends CurrentUserDto {
  nextToken: string;
  picklistToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private redirectUrl: string | null;

  public constructor(
    private authStore: AuthStore,
    private httpClient: HttpClient,
    private userService: UserService,
    private nextService: NextService,
    private tokenService: TokenService,
    private logger: NGXLogger
  ) {
  }

  public async login(username: string, password: string): Promise<void> {
    this.authStore.reset();
    const loginResponse = await this.authenticate(username, password);
    this.authStore.setCurrentUser(loginResponse);
    this.authStore.setNextAuthToken(loginResponse.nextToken);
    this.authStore.setPicklistAuthToken(loginResponse.picklistToken);
    this.authStore.setAuthenticationMethod(AuthenticationMethod.BasicAuth);
    this.logger.setCustomHttpHeaders(new HttpHeaders({Authorization: 'Bearer ' + loginResponse.picklistToken}));
  }

  public async loginSso(username: string, signature: string, token: string): Promise<void> {
    this.authStore.reset();
    const loginResponse = await this.authenticateSso(username, signature);
    this.authStore.setCurrentUser(loginResponse);
    this.authStore.setSsoSignature(signature);
    this.authStore.setNextAuthToken(token);
    this.authStore.setPicklistAuthToken(loginResponse.picklistToken);
    this.authStore.setAuthenticationMethod(AuthenticationMethod.Sso);
    this.logger.setCustomHttpHeaders(new HttpHeaders({Authorization: 'Bearer ' + loginResponse.picklistToken}));
  }

  public async refreshSso(): Promise<boolean> {
    const username = this.authStore.getCurrentUser()?.userName;
    const signature = this.authStore.getSsoSignature();
    const token = this.authStore.getNextAuthToken();
    if (username && signature && token) {
      const refreshResponse = await this.tokenService.refreshSso({
        username,
        signature,
        token
      }).toPromise();
      this.authStore.setPicklistAuthToken(refreshResponse.token);
      this.authStore.setNextAuthToken(refreshResponse.nextToken);
      this.authStore.setSsoSignature(refreshResponse.signature);
      return true;
    } else {
      return false;
    }
  }

  public async logout(): Promise<void> {
    // TODO: Replace this (+ proxy.config entry) as soon as real authentication is implemented
    // await this.httpClient.get('/api/logout').toPromise()
    this.authStore.reset();
  }

  public async refreshCurrentUser(): Promise<void> {
    const token = this.authStore.getNextAuthToken();
    if (!token) {
      throw new Error('Can not refresh, no token set');
    }
    const user = await this.userService.getCurrentUser().toPromise();
    this.authStore.setCurrentUser(user);
  }

  public setRedirectUrl(url: string | null) {
    this.redirectUrl = url;
  }

  public getRedirectUrl(): string | null {
    return this.redirectUrl;
  }

  private async authenticate(username: string, password: string): Promise<LoginResponse> {
    const nextResponse = await this.nextService.getAuthToken({
      authId: 'xwss',
      traderId: 487,
      catalogId: 134,
      username,
      password,
      languageId: 1,
    }).toPromise();

    // console.log(nextResponse.token);
    const picklistResponse = await this.tokenService.exchange({
      token: nextResponse.token
    }).toPromise();
    return {
      userName: username,
      name: username,
      nextToken: nextResponse.token,
      picklistToken: picklistResponse.token
    };
  }

  private async authenticateSso(username: string, signature: string): Promise<LoginResponse> {
    const picklistResponse = await this.tokenService
      .exchangeSso({ username, signature}).toPromise();

    return {
      userName: username,
      name: username,
      nextToken: '',
      picklistToken: picklistResponse.token
    };
  }
}
