import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthStore} from '@common/store/auth.store';
import {environment} from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  public constructor(
    private authStore: AuthStore
  ) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let req = request.clone();

    let token = null;

    if (request.url && request.url.search(environment.picklistBaseUrl) !== -1) {
      token = this.authStore.getPicklistAuthToken();
    }

    if (request.url && request.url.search(environment.nextBaseUrl) !== -1) {
      token = this.authStore.getNextAuthToken();
    }

    if (token) {
      req = request.clone({
        setHeaders: {Authorization: 'Bearer ' + token}
      });
    }

    return next.handle(req);
  }

}
