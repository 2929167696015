import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NextService, UrlResponseDto, VoucherDto} from '@next-api';
import * as moment from 'moment';
import {PicklistCarLicenceResultDto} from '@api';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-next',
  templateUrl: './next.component.html',
  styleUrls: ['./next.component.scss']
})
export class NextComponent {
  @Input() public result: PicklistCarLicenceResultDto | null = null;
  @Output() public newCase = new EventEmitter();

  private taskResult: UrlResponseDto | null = null;

  public constructor(private readonly nextService: NextService,
                     private readonly snackBar: MatSnackBar,
                     private readonly translate: TranslateService) {
  }

  public createCase(): void {
    this.getImportVoucherResult()
      .then(async result => {
        this.openTaskResultPage(result);
      })
      .catch(async err => {
        await this.showConnectionError();
      });
  }

  private async showConnectionError() {
    const msg = await this.translate.get('next.connection.error').toPromise();
    const ok = await this.translate.get('next.connection.error.ok').toPromise();
    this.snackBar.open(msg, ok, {
      duration: 5000
    });
  }

  private openTaskResultPage(dto: UrlResponseDto) {
    if (dto && dto.portalUrl) {
        window.open(dto.portalUrl, '_blank')?.focus();
      }
  }

  private async getImportVoucherResult(): Promise<UrlResponseDto> {
    if (this.taskResult !== null) {
      return Promise.resolve(this.taskResult);
    }
    if (this.result !== null) {
      return this.importVoucher(this.result)
        .then(res => this.taskResult = res);
    }
    return Promise.reject('Task result missing');
  }

  private async importVoucher(dto: PicklistCarLicenceResultDto): Promise<UrlResponseDto> {
    const registrationDateStr = moment(dto.erstzulassung, 'dd.MM.yyyy').toISOString();
    return this.nextService.importVoucher('de',
      {
        referenceId: undefined,
        vehicle: {
          vin: dto.vin,
          registrationInformation: {
            countryCode: 'AT',
            plateId: dto.kennzeichen,
            registrationNo: dto.nationalerCode,
            registrationDate: registrationDateStr
          },
          vehicleType: {
            id: parseInt(dto.tecDocId, 10), type: 0
          },
          mileage: dto.kilometerstand,
          mileageType: dto.kilometerstand ? 1 : null,
          engineCode: dto.motortype
        }
      } as VoucherDto
    ).toPromise();
  }
}
