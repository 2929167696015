<app-navbar></app-navbar>
<div class="background-image">
    <div class="black-fade"></div>
    <div class="content" *ngIf="result == null">
        <app-upload (uploaded)="fileUploaded($event)" [hidden]="file != null"></app-upload>

        <div class="processing-container" [hidden]="file == null">
            <div class="processing-spinner">
                <span class="processing-text"
                      *ngIf="uploading; else elseBlock">{{'dashboard.uploading' | translate}}</span>
                <ng-template #elseBlock>
                    <span class="processing-text">{{'dashboard.processing' | translate}}</span>
                </ng-template>
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
    <div class="dashboard-container" *ngIf="result != null">
        <div *ngIf="file != null && imageConversionPicture != null"
             class="image-preview-container transparent-background">
            <div *ngIf="file.name != null" [textContent]="file.name" class="image-preview-header"></div>
            <image-preview
                    [imageMetaData]="imageConversionPicture"
                    class="image-preview"></image-preview>
        </div>
        <div class="stepper-container transparent-background">
            <app-stepper
                    [result]="result"
                    class="stepper"
                    (cancel)="cancelProcess()"></app-stepper>
        </div>
    </div>
</div>
