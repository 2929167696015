<div class="next">
    <div class="content">
        <div class="single-content">
            <span class="next-header">{{'next.header' | translate}}</span>
        </div>
        <div class="single-content">
            <span class="next-text">{{'next.description' | translate}}</span>
        </div>
        <div class="button-content">
            <button class="custom-button" mat-flat-button (click)="createCase()" *ngIf="result != null">
                <mat-icon class="custom-icon" aria-hidden="false" aria-label="next icon">check</mat-icon>
                {{'next.button.open' | translate}}
            </button>
        </div>
    </div>
</div>

