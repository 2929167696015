<mat-card *ngIf="result != null">
    <form [formGroup]="resultFormGroup" (ngSubmit)="updateData()">
        <mat-card-content>
            <div class="form-input">
                <mat-form-field class="custom-form-field" appearance="fill" color="primary">
                    <mat-label>{{'result.license_plate' | translate}}</mat-label>
                    <input matInput type="text" [(ngModel)]="result.kennzeichen" formControlName="license_plate"
                            [errorStateMatcher]="instantErrorMatcher">
                </mat-form-field>
            </div>
            <div class="form-input">
                <mat-form-field class="custom-form-field" appearance="fill">
                    <mat-label>{{'result.first_registration' | translate}}</mat-label>
                    <input matInput type="text" [(ngModel)]="result.erstzulassung" formControlName="first_registration"
                           [pattern]="datePattern"  [errorStateMatcher]="instantErrorMatcher">
                    <mat-error
                            *ngIf="resultFormGroup.controls.first_registration.invalid">{{'result.first_registration.format_error' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="form-input">
                <mat-form-field class="custom-form-field" appearance="fill">
                    <mat-label>{{'result.mileage' | translate}}</mat-label>
                    <input matInput type="text" [pattern]="mileagePattern" [(ngModel)]="result.kilometerstand" formControlName="mileage"
                           [errorStateMatcher]="instantErrorMatcher">
                    <mat-error
                            *ngIf="resultFormGroup.controls.mileage.invalid">{{'result.mileage.integer' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="form-input">
                <mat-form-field class="custom-form-field" appearance="fill">
                    <mat-label>{{'result.vin' | translate}}</mat-label>
                    <input matInput type="text" [(ngModel)]="result.vin" formControlName="vin"
                           [errorStateMatcher]="instantErrorMatcher">
                </mat-form-field>
            </div>
            <div class="form-input">
                <mat-form-field class="custom-form-field" appearance="fill">
                    <mat-label>{{'result.motortype' | translate}}</mat-label>
                    <input matInput type="text" [(ngModel)]="result.motortype" formControlName="motortype">
                </mat-form-field>
            </div>
            <div class="form-input">
                <mat-form-field class="custom-form-field" appearance="fill">
                    <mat-label>{{'result.national_code' | translate}}</mat-label>
                    <input matInput type="text" [(ngModel)]="result.nationalerCode" formControlName="national_code"
                           required [pattern]="nationalCodePattern" [maxLength]="nationalCodeMaxLength" [errorStateMatcher]="instantErrorMatcher">
                    <mat-error
                            *ngIf="resultFormGroup.controls.national_code.invalid">{{'result.national_code.required' | translate}}</mat-error>
                </mat-form-field>
            </div>
        </mat-card-content>
    </form>
</mat-card>

