<div class="stepper-content">
    <div [hidden]="vertical">
        <mat-horizontal-stepper class="transparent-background" [linear]="true" #horizontalStepper>
            <ng-template matStepperIcon="edit">
                <mat-icon>check</mat-icon>
            </ng-template>
            <mat-step [editable]="false">
                <ng-template matStepLabel>{{'dashboard.photo.upload' | translate}}</ng-template>
            </mat-step>
            <mat-step completed="false">
                <ng-template matStepLabel>{{'dashboard.datacheck' | translate}}</ng-template>
                <app-result [result]="result" (updated)="updatedResult($event)"></app-result>
            </mat-step>
            <mat-step completed="false">
                <ng-template matStepLabel>{{'dashboard.qrcode' | translate}}</ng-template>
                <app-qr [result]="result" (done)="stepperNext()" #horizontalQR></app-qr>
            </mat-step>
            <mat-step completed="false">
                <ng-template matStepLabel>{{'dashboard.createcase' | translate}}</ng-template>
                <app-next [result]="result" (newCase)="cancelProcess()"></app-next>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
    <div [hidden]="!vertical">
        <mat-vertical-stepper [hidden]="!vertical" class="transparent-background" [linear]="true" #verticalStepper>
            <ng-template matStepperIcon="edit">
                <mat-icon>check</mat-icon>
            </ng-template>
            <mat-step [editable]="false">
                <ng-template matStepLabel>{{'dashboard.photo.upload' | translate}}</ng-template>
            </mat-step>
            <mat-step completed="false">
                <ng-template matStepLabel>{{'dashboard.datacheck' | translate}}</ng-template>
                <app-result [result]="result" (updated)="updatedResult($event)"></app-result>
            </mat-step>
            <mat-step completed="false">
                <ng-template matStepLabel>{{'dashboard.qrcode' | translate}}</ng-template>
                <app-qr [result]="result" (done)="stepperNext()" #verticalQR></app-qr>
            </mat-step>
            <mat-step completed="false">
                <ng-template matStepLabel>{{'dashboard.createcase' | translate}}</ng-template>
                <app-next [result]="result" (newCase)="cancelProcess()"></app-next>
            </mat-step>
        </mat-vertical-stepper>
    </div>
    <div class="stepper-buttons">
        <mat-spinner *ngIf="loading" class="loadingSpinner" [diameter]="28"></mat-spinner>
        <button type="button" class="white-button" (click)="cancelProcess()"
                mat-raised-button>{{'dashboard.cancel' | translate}}</button>
        <button *ngIf="backPossible" type="button" class="white-button" (click)="stepperBack()"
                mat-raised-button>{{'dashboard.back' | translate}}</button>
        <button *ngIf="nextPossible" type="button" class="yellow-button" (click)="onNext()" mat-raised-button
                [disabled]="!isValid">{{'dashboard.next' | translate}}
        </button>
    </div>
</div>
